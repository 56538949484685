import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import styles from './styles.module.css';
import { ReactComponent as Loader } from '../../assets/loader.svg';
import { getDatabase, ref as refDB, push, set, get } from "firebase/database";

export default function Login() {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPass, setEmptyPass] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const database = getDatabase();
  const loginActionsListRef = refDB(database, 'loginActions');
  const newLoginActionsRef = push(loginActionsListRef);
  const [result, setResult] = useState<any>();

  useEffect(() => {
    let data: any = {
      currentTime: String(new Date()),
      timezone: (new Date().getTimezoneOffset()/60),
      //pageon: window.location.pathname,
      referrer: document.referrer,
      // eslint-disable-next-line no-restricted-globals
      previousSites: history.length,
      browserName: navigator.appName,
      browserCodeName: navigator.appCodeName,
      browserEngine: navigator.product,
      browserVersion1a: navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      //dataCookies: document.cookie,
      //dataStorage: localStorage,
      // eslint-disable-next-line no-restricted-globals
      screenOrientationAngle: screen.orientation.angle,
      // eslint-disable-next-line no-restricted-globals
      screenOrientationType: screen.orientation.type,
      //@ts-ignore
      // eslint-disable-next-line no-restricted-globals
      screenIsExtended: screen.isExtended,
      // eslint-disable-next-line no-restricted-globals
      sizeScreenW: screen.width,
      // eslint-disable-next-line no-restricted-globals
      sizeScreenH: screen.height,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      // eslint-disable-next-line no-restricted-globals
      sizeAvailW: screen.availWidth,
      // eslint-disable-next-line no-restricted-globals
      sizeAvailH: screen.availHeight,
      // eslint-disable-next-line no-restricted-globals
      scrColorDepth: screen.colorDepth,
      // eslint-disable-next-line no-restricted-globals
      scrPixelDepth: screen.pixelDepth,

      //canShare: navigator?.canShare(),
      hardwareConcurrency: navigator.hardwareConcurrency,
      pdfViewerEnabled: navigator.pdfViewerEnabled,
      browserEngineSub: navigator.productSub,
      vendor: navigator.vendor,
      vendorSub: navigator.vendorSub,
      //@ts-ignore
      //hasBluetooth: hasBlueetoth(),
      //@ts-ignore
      //connectionDownLink: navigator.connection.downlink,
      //@ts-ignore
      //connectionEffectiveType: navigator.connection.effectiveType,
      //@ts-ignore
      //connectionRTT: navigator.connection.rtt,
      //@ts-ignore
      deviceMemory: navigator.deviceMemory,
      //@ts-ignore
      //userAgentDataBrands: navigator.userAgentData.brands,
      //@ts-ignore
      //userAgentDataIsMobile: navigator.userAgentData.mobile,
      //@ts-ignore
      //userAgentDataPlatform: navigator.userAgentData.platform,
      webdriver: navigator.webdriver,
      isSecureContext: window.isSecureContext
    };

    function hasBlueetoth () {
      //@ts-ignore
      navigator.bluetooth.getAvailability().then(response => data.hasBluetooth = response);
    }

    fetch("https://ipinfo.io/json?token=950de291781bff").then(
    (response) => response.json()
    ).then(
    (jsonResponse) => {
      data.ipInfos = {
        ip: jsonResponse.ip,
        city: jsonResponse.city,
        country: jsonResponse.country,
        hostname: jsonResponse.hostname,
        loc: jsonResponse.loc,
        org: jsonResponse.org,
        postal: jsonResponse.postal,
        region: jsonResponse.region,
        timezone: jsonResponse.timezone
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setResult(data);
    })

    onAuthStateChanged(auth, (user: any) => {
         if (user) {
          console.log(user)
              localStorage.setItem('userMail', user.email);
              navigate("/", { replace: true});
         }
       });
},[user, navigate, auth]);

useEffect(() => {
  setWrongCredentials(false);
  setEmptyEmail(false);
  setEmptyPass(false);
}, [email, password]);

  const handleSubmit = () => {
    setIsLoading(true);

    if(email === ''){
      setEmptyEmail(true);
    } else {
      setEmptyEmail(false);
    }

    if(password === ''){
      setEmptyPass(true);
    } else {
      setEmptyPass(false);
    }

    if(email === '' || password === ''){
      setIsLoading(false);
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      set(newLoginActionsRef, {
        ...result
      });

      setIsLoading(false);
      navigate("/", { replace: true});
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setIsLoading(false);
      setWrongCredentials(true);
    });
  };

  const handleKeypress = (event: any) => {
    if(event.key === 'Enter'){
      handleSubmit();
    }
  }

  return (
      <div className={styles.bgScreen}>
        <div className={styles.bgForm}>
          <div>
            <label>E-mail</label>
            <input type="text" name="email" placeholder="example@mail.com" value={email} onChange={e => setEmail(e.target.value)} onKeyUp={e => handleKeypress(e)} />
            {emptyEmail && <span>Digite um e-mail válido</span>}
          </div>
          <div className="input-container">
            <label>Senha </label>
            <input type="password" name="password" placeholder="Digite sua senha..." value={password} onChange={e => setPassword(e.target.value)} onKeyUp={e => handleKeypress(e)} />
            {emptyPass && <span>Digite uma senha válida</span>}
          </div>
          {wrongCredentials && <span className={styles.wrongCredentials}>E-mail ou senha inválido.</span>}
          <div>
            <button onClick={handleSubmit} disabled={isLoading}>
              {!isLoading ? 'Entrar' : <Loader className={styles.spinner} />}
            </button>
          </div>
        </div>
      </div>
  )
}