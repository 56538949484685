import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getDatabase, ref as refDB, push, set, get } from "firebase/database";
import { GlobalContext } from "../../contexts/GlobalContext";
import Player from "../player";
import styles from './styles.module.css';
import Header from "../header";
import Sidebar from "../sidebar";
import Collection from "../collection";

export default function Home() {
     const { state, dispatch } = useContext(GlobalContext);
     const auth = getAuth();
     const user = auth.currentUser;
     const navigate = useNavigate();
     const [imgUrl, setImgUrl] = useState('');
     const [audioUrl, setAudioUrl] = useState('');
     const [title, setTitle] = useState('');
     const [autor, setAutor] = useState('');
     const [progresspercent, setProgresspercent] = useState(0);
     const storage = getStorage();
     const database = getDatabase();
     const songsListRef = refDB(database, 'songs');
     const newSongRef = push(songsListRef);
     const [getSongs, setGetSongs] = useState<any>(null);
     const [currentSongPlaying, setCurrentSongPlaying] = useState('');

     const[hidden,setHidden] = useState(true);
     const[r, setR] = useState(false);
     const[a, setA] = useState(false);
     const[i, setI] = useState(false);

     useEffect(() => {
          window.addEventListener("keypress", (event) => {
               if(event.key === 'r' || event.key === 'R'){
                    setR(true)
               }
               if(event.key === 'a' || event.key === 'A'){
                    setA(true)
               }
               if(event.key === 'i' || event.key === 'I'){
                    setI(true)
               }
          });
          document.addEventListener('visibilitychange', () => {
               setHidden(true);
               setR(false);
               setA(false);
               setI(false);
          });
     },[]);

     useEffect(() => {
          if(r && a && i){
               setHidden(false);
          }
     },[r,a,i]);

     useEffect(() => {
          onAuthStateChanged(auth, (user) => {
               if (!user) {
                    navigate("/login", { replace: true});
               } else {
                    get(songsListRef).then((snapshot) => {
                    if (snapshot.exists()) {

                    const allDataMusic = Object.keys(snapshot.val()).map((keyname, index) => {
                         return {
                              album: snapshot.val()[keyname].album,
                              artURL: snapshot.val()[keyname].artURL,
                              artist: snapshot.val()[keyname].artist,
                              duration: snapshot.val()[keyname].duration,
                              filesKey: snapshot.val()[keyname].filesKey,
                              mp3URL: snapshot.val()[keyname].mp3URL,
                              title: snapshot.val()[keyname].title,
                              year: snapshot.val()[keyname].year
                         }
                    });

                    dispatch({
                         type: 'PLAYER_CHANGE_PROPS',
                         payload: {
                           props: [ ...allDataMusic ]
                         }
                    });

                    dispatch({
                         type: 'PLAYER_CHANGE_TOTALMUSICS',
                         payload: {
                           totalMusics: allDataMusic.length
                         }
                    });

                    setGetSongs(snapshot.val());
                    } else {
                    }
                    }).catch((error) => {
                    });
               }
             });
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[user, navigate, auth]);

     /* useEffect(() => {
          if(imgUrl !== '' && audioUrl !== ''){
               set(newSongRef, {
                    "title": title,
                    "autor": autor,
                    "imgURL": imgUrl,
                    "audioURL": audioUrl,
                });
                setImgUrl('');
                setAudioUrl('');
                setTitle('');
                setAutor('');
                get(songsListRef).then((snapshot) => {
                    if (snapshot.exists()) {
                    console.log(snapshot.val());
                    setGetSongs(snapshot.val());
                    } else {
                    console.log("No data available");
                    }
                    }).catch((error) => {
                    console.error(error);
                    });
          }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[imgUrl, audioUrl]);
 */
     /* const handleSubmit = (event: any) => {
          event.preventDefault();
          const fileImage = event.target[0]?.files[0];
          const fileAudio = event.target[3]?.files[0];

          if (!fileImage || !fileAudio || !title || !autor) return;

          const imagesRef = ref(storage, `images/${fileImage.name}`);
          const audiosRef = ref(storage, `audios/${fileAudio.name}`);

          const uploadTaskImage = uploadBytesResumable(imagesRef, fileImage);
          const uploadTaskAudio = uploadBytesResumable(audiosRef, fileAudio);

          uploadTaskImage.on("state_changed",
               (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
               },
               (error) => {
                    alert(error);
               },
               () => {
                    getDownloadURL(uploadTaskImage.snapshot.ref).then((downloadURL) => {
                    setImgUrl(downloadURL)
               });
               }
          );

          uploadTaskAudio.on("state_changed",
               (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
               },
               (error) => {
                    alert(error);
               },
               () => {
                    getDownloadURL(uploadTaskAudio.snapshot.ref).then((downloadURL) => {
                    setAudioUrl(downloadURL)
               });
               }
          );
     } */

    return (
     <div className={styles.home}>
          {hidden && <div className={styles.hidden}><input type="text" onChange={e => e.target.value === 'rai' ? setHidden(false) : setHidden(true)}/></div>}
          <div className={styles.homeGrid}>
               <Sidebar />
               <div className={styles.rightArea}>
                    <Header />
                    <Collection />
                    {state.player.currentMusic && <Player />}
               </div>
               {/* <form onSubmit={handleSubmit} className='form'>
                    <input type='file' />
                    <input type="text" name="title" value={title} onChange={e => setTitle(e.target.value)} />
                    <input type="text" name="autor" value={autor} onChange={e => setAutor(e.target.value)} />
                    <input type='file' />
                    <button type='submit'>Upload</button>
               </form>
               <div className='outerbar'>
                    <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
               </div> */}
          </div>
          {/* {getSongs && Object.keys(getSongs).map((keyName, i) => (
               <div className={styles.song} key={i} onClick={() => setCurrentSongPlaying(getSongs[keyName].audioURL)}>
                    <p>{getSongs[keyName].title}</p>
               </div>
          ))}
          {!getSongs && <p>Nenhum registro encontrado.</p>}

          {currentSongPlaying !== '' && <Player song={currentSongPlaying} />} */}
     </div>
    )
  }