import { getDatabase, ref as refDB, get, push, set, onValue, limitToLast, query, orderByChild } from "firebase/database";
import styles from './styles.module.css';
import { useEffect, useState, useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import { Theme } from "emoji-picker-react";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export default function Chat() {
    const [message, setMessage] = useState('');
    const database = getDatabase();
     const chatListRef = refDB(database, 'chat');
     const newMessageRef = push(chatListRef);
     const [getChat, setGetChat] = useState<any>(null);
     const [user, setUser] = useState<any>('');
     const messageEl = useRef<any>(null);
     const [emojiOpen, setEmojiOpen] = useState(false);
     const storage = getStorage();
     const [isDisabled, setIsDisabled] = useState(false);

     const handleEmojiClick = (emojiObject: any, e: any) => {
      setMessage(message + emojiObject.emoji);
    }

    const handleOpenEmoji = () => {
      setEmojiOpen(!emojiOpen);
    }

    const handleFileSelection = (event: any) => {
      uploadFile(event.target.files[0]);
    }

    const handlePaste = (event: any) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf("image") !== -1) {
          setIsDisabled(true);
          const blob = item.getAsFile();
          uploadFile(blob);
        }
      }
    };

    const openLinkInNewTab = (url: any) => {
      window.open(url, '_blank');
    };

    const uploadFile = (file: any) => {
      console.log(file)
      const newFileName = file.name.split('.');
      const imagesRef = ref(storage, `chatImages/${newFileName[0] + '-' + Date.now() + '.' + newFileName[1]}`);
      const uploadTaskImage = uploadBytesResumable(imagesRef, file);

      uploadTaskImage.on("state_changed",
        (snapshot) => {},
          (error) => {
          alert(error);
          },
          () => {
          getDownloadURL(uploadTaskImage.snapshot.ref).then((downloadURL) => {
            set(newMessageRef, {
              "message": downloadURL,
              "user": user,
              "time": Date.now(),
              "media": true
          });
        });
        setIsDisabled(false);
        }
      );
    }

     useEffect(() => {
        if (messageEl) {
          messageEl.current.addEventListener('DOMNodeInserted', (event: any) => {
            const { currentTarget: target } = event;
            target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
          });
        }
      }, [])

     useEffect(() => {
        const userMail = window.localStorage.getItem('userMail');
        setUser(userMail);
        const last50Query = query(chatListRef, orderByChild("date"), limitToLast(50)); //chatListRef
        onValue(last50Query, (snapshot) => {
            const data = Object.keys(snapshot.val()).map((keyname, index) => {
                return {
                    user: snapshot.val()[keyname].user,
                    message: snapshot.val()[keyname].message,
                    date: snapshot.val()[keyname].time,
                    media: snapshot.val()[keyname].media
                }
            });
            setGetChat(data);
            document?.getElementById('chatArea')?.scrollIntoView();
        })
        /* get(chatListRef).then((snapshot) => {
            if (snapshot.exists()) {

            const allMessages = Object.keys(snapshot.val()).map((keyname, index) => {
                 return {
                      user: snapshot.val()[keyname].user,
                      message: snapshot.val()[keyname].message,
                 }
            });

            setGetChat(allMessages);
            } else {
            }
            }).catch((error) => {
            }); */
     },[]);

     const handleKeypress = (event: any) => {
        if(event.key === 'Enter'){
          handleSubmit();
        }
      }

      const handleSubmit = () => {
        if(message === ''){
          return false;
        }

        setEmojiOpen(false);

        set(newMessageRef, {
            "message": message,
            "user": user,
            "time": Date.now(),
        });
        setMessage('');
       /*  get(songsListRef).then((snapshot) => {
            if (snapshot.exists()) {
            console.log(snapshot.val());
            setGetSongs(snapshot.val());
            } else {
            console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            }); */
      };

      const createDate = (date: any) => {
        let d = new Date(date);
        let hours: any = d.getHours();
        let minutes: any = d.getMinutes();
        hours = hours < 10 ? '0'+hours : hours;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        return `${hours}:${minutes}`;
      }

    return (
        <div className={styles.chat}>
            <div className={styles.messages} ref={messageEl}>
                {getChat?.map((item: any, i: any) => (
                    <>
                      {!item.media && <p key={i} className={item.user === user ? styles.me : styles.other}>{item.message}<span>{createDate(item.date)}</span></p>}
                      {item.media && <div className={item.user === user ? styles.me : styles.other}><img className={styles.imageArea} src={item.message} alt="" onClick={() => openLinkInNewTab(item.message)}></img><span>{createDate(item.date)}</span></div>}
                    </>
                ))}
                {/* <p className={styles.other}>ola</p>
                <p className={styles.me}>ola tudo bem</p>
                <p className={styles.other}>tudo e vc</p>
                <p className={styles.me}>td</p> */}
            </div>
            <div className={styles.talk}>
                <input disabled={isDisabled} type="text" name="message" value={message} onPaste={handlePaste} onChange={e => setMessage(e.target.value)} onKeyUp={e => handleKeypress(e)} placeholder='Digite a mensagem...' />
                <div className={styles.emojiArea} style={{height: emojiOpen? '200px':'0px'}}>
                  <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  skinTonesDisabled
                  searchDisabled
                  theme={Theme.DARK}
                  lazyLoadEmojis={true}
                  />
                </div>
                <div className="chatWindow--pre">
                  <div className={styles.emoji} onClick={handleOpenEmoji}>
                  😀
                  </div>
                </div>
                <div className={styles.sendImage}>
                  <label htmlFor="upload">📷</label>
                  <input id="upload" type='file' accept="image/*" onChange={handleFileSelection}/>
                </div>
                <button onClick={handleSubmit}>Enviar</button>
            </div>
        </div>
    )
}